import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css']
})
export class AccordionComponent implements OnInit {
@Input() benefiniItem: string;
@Input() notItem: string;
@Input() chinesiologoItem: string;
@Input() detto: string;
  constructor() { }

  ngOnInit(): void {
  }

}
