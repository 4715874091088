import { CarouselMComponent } from './carousel-m/carousel-m.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

import {NgModule} from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import { CarouselComponent } from './carousel/carousel.component';
import { ModalComponent } from './modal/modal.component';
import { CardComponent } from './card/card.component';
import { AccordionComponent } from './accordion/accordion.component';
import { VideoComponent } from './video/video.component';
import { Modal2Component } from './modal2/modal2.component';
import { Modal3Component } from './modal3/modal3.component';

const components = [
 CarouselComponent,
 CardComponent,
 AccordionComponent,
 VideoComponent,
 CarouselMComponent

];

@NgModule({
  declarations: [
    ...components,
    ModalComponent,
    CardComponent,
    AccordionComponent,
    VideoComponent,
    Modal2Component,
    Modal3Component
  ],
  exports: [
    ...components
  ],
  imports: [
    CommonModule,
    NgbModule,
    MDBBootstrapModule.forRoot()


  ]
})
export class SharedModule {}
