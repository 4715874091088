<div style="text-align: left;outline: 0;">
    <div class="card marg">
        <img [src]="path" alt="Card image cap">
    </div>
    <div class="titcard">
        <label style="color: #00a9bb;
    padding-top: 2%;
    font-size: 15px;
    font-family: 'Roboto';
    outline: 0;">{{title}}</label>
    </div>
</div>