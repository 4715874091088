import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-carousel-m',
  templateUrl: './carousel-m.component.html',
  styleUrls: ['./carousel-m.component.css']
})
export class CarouselMComponent implements OnInit {

  constructor(
    config: NgbCarouselConfig,
    private router : Router
    ) {
    config.interval = 3000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
    config.showNavigationArrows = false;
    config.animation = true;
  }

  ngOnInit() {

  }
  link1(){
    this.router.navigate(['patdifairplay/progetto']);
  }

  link2(){
    this.router.navigate(['patdifairplay/registrati']);
  }

  link3(){
    this.router.navigate(['patdifairplay/videoCorsi']);
  }

}
