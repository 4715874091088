<div class="boxModale">

  <div class="modal-body">

      <div style="
  margin-left: auto;
  margin-right: auto;
  text-align: center;">

    <label class="fontS">Per seguire le lezioni gratuite</label>
  <div class="row">
    <a style="width: 100%;" href="https://www.progettopat.it/patdifairplay/registrati"> <button class="btn btn-block btn1" >ISCRIVITI</button></a>


  </div>

    <label class="fontS">Oppure</label>

  <div class="row">
   <a style="width: 100%;" href="https://www.progettopat.it/patdifairplay/login"><button class="btn btn-block btn2" (click)="savePath()">ACCEDI</button></a>
  </div>



          <!-- <button type="button" (click)="activeModal.dismiss('Cross click')" class="btn  btn-lg" id="btnSave" style="width: 70%;background-color: #00a9bb;margin-top: 25%;">{{btn}}</button>-->
      </div>
  </div>
