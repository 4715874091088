import { Modal3Component } from './../modal3/modal3.component';
import { ModalComponent } from './../modal/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SafeUrl, SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  @Input() titolo: string;
  @Input() video: string;
  @Input() show: boolean;
  dangerousUrl: string;
trustedUrl: SafeUrl;
dangerousVideoUrl: string;
videoUrl: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.updateVideoUrl(this.video);
  }

  updateVideoUrl(id: string) {
    // Appending an ID to a YouTube URL is safe.
    // Always make sure to construct SafeValue objects as
    // close as possible to the input data so
    // that it's easier to check if the value is safe.

    this.dangerousVideoUrl = 'https://www.youtube.com/embed/' + id + '?rel=0';
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousVideoUrl);
  }

  toAccedi(){
    const modalRef = this.modalService.open(Modal3Component);


  }
}
