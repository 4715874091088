import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {
@Input() path: string;
@Input() title: string;
@Input() id: string;

  constructor() { }

  ngOnInit(): void {
  }





}
