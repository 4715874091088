<div>
    <mdb-carousel class="carousel slide carousel-fade" [isControls]="false" [animation]="'fade'">
        <mdb-carousel-item>
            <div class="view w-100">
                <img class="d-block w-100" src="assets/carousel/M1.jpg" alt="First slide" (click)="link2()">
                <div class=" rgba-black-light waves-light" mdbWavesEffect></div>
            </div>

        </mdb-carousel-item>
        <mdb-carousel-item>
            <div class="view w-100">
                <img class="d-block w-100" src="assets/carousel/M3.jpg" alt="Second slide" (click)="link3()">
                <div class=" rgba-black-strong waves-light" mdbWavesEffect></div>
            </div>
        </mdb-carousel-item>
        <mdb-carousel-item>
            <div class="view w-100">
                <img class="d-block w-100" src="assets/carousel/M2.jpg" alt="Third slide" (click)="link1()">
                <div class=" rgba-black-slight waves-light" mdbWavesEffect></div>
            </div>

        </mdb-carousel-item>
    </mdb-carousel>
</div>
