<ngb-accordion #acc="ngbAccordion" [closeOthers]="true" [animation]="true" class="range-accordion">
    < <ngb-panel>
        <ng-template ngbPanelTitle>
            <div class="d-flex justify-content-between">
                <div class="d-flex" style=" color: #1c2128;">Benefici</div>
                <div class="d-flex" style=" color: #1c2128;"><i class="fa fa-chevron-down"></i></div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            {{benefiniItem}}
        </ng-template>
        </ngb-panel>

        <ngb-panel id="toggle-2">
            <ng-template ngbPanelTitle>
                <div class="d-flex justify-content-between">
                    <div class="d-flex" style=" color: #1c2128;">A chi è sconsigliato</div>
                    <div class="d-flex" style=" color: #1c2128;"><i class="fa fa-chevron-down"></i></div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                {{notItem}}
            </ng-template>
        </ngb-panel>
        <ngb-panel id="toggle-3">
            <ng-template ngbPanelTitle>
                <div class="d-flex justify-content-between">
                    <div class="d-flex" style=" color: #1c2128;">Il chinesiologo</div>
                    <div class="d-flex" style=" color: #1c2128;"><i class="fa fa-chevron-down"></i></div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>

                <label>   {{chinesiologoItem}}</label>
              <label style="font-style: oblique;">  {{detto}}</label>
            </ng-template>
        </ngb-panel>
</ngb-accordion>
