import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal3',
  templateUrl: './modal3.component.html',
  styleUrls: ['./modal3.component.css']
})
export class Modal3Component implements OnInit {
path: string;
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  toLogin(){
console.log("login");
this.router.navigate([`/patdifairplay/login/`]);


  }

  savePath(){
    console.log(window.location.href);
    this.path = window.location.href;

    this.path = this.path.substring(this.path.indexOf("o/")+2);
    this.path.trim();
    console.log( this.path);
    localStorage.setItem('path', this.path);
    localStorage.setItem('bPath', 'true');
  }



}
