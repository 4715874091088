import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {

  constructor(
    config: NgbCarouselConfig,
    private router : Router
    ) {
    config.interval = 3000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
    config.showNavigationArrows = false;
    config.animation = true;
  }

  ngOnInit() {

  }
    link1(){
      this.router.navigate(['patdifairplay/progetto']);
    }

    link2(){
      this.router.navigate(['patdifairplay/registrati']);
    }

    link3(){
      this.router.navigate(['patdifairplay/videoCorsi']);
    }



}
