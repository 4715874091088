<div style="margin-left: 6%; margin-right: 6%;">


    <div class="video-container" *ngIf="show">
      <iframe [src]="videoUrl" frameborder="0" allow=" encrypted-media; gyroscope;" [src]="videoUrl" webkitallowfullscreen mozallowfullscreen allowfullscreen webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </div>

    <div class="row" *ngIf="!show" (click)="toAccedi()">
      <img src="assets/screenVideo/blocked.png" style="width: 100%;">
  </div>




    <div class="row">
        <h2 style=" text-align: left!important; margin-bottom: 0px; ">{{titolo}}</h2>
    </div>
    <br>


</div>
